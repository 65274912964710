<script setup>
import Container from "./Container.vue";
import PreviousButton from "./PreviousButton.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import PhoneNumberInput from "@/components/inputs/PhoneNumberInput.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import store from "@/store/index";
import {
    fieldRequired,
    validateIfNotEmpty,
    emailValid,
    isNumber,
    passwordConfirmation,
    passwordRules,
    minLength,
} from "@/utils/input_validation.js";
import {
    getJsDateAsYMD,
    convertYearsToMs,
    addPostalCodeToPostalTown,
} from "@/utils/functions";
import {
    examplePhoneNumber,
    passwordSpecialCharacters,
} from "@/utils/constants";
import {useSubcountyWard, countyIsKirinyaga, scrollToError } from "@/utils/vue_helpers";
import { ref, computed, watchEffect, watch, onMounted } from "vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import TermsAndConditions from "./TermsAndConditions.vue";
import PersonWithDisability from "@/components/utils/PersonWithDisability.vue";
import NextButton from "./NextButton.vue";

const props = defineProps({
    accountDetailsModels: {
        type: Object,
        default: null,
    },
    additionalDetailsModels: {
        type: Object,
        default: null,
    },
    isIndividual: {
        type: Boolean,
        default: false,
    },
    shouldCreateAccount: {
        type: Boolean,
        default: false,
    },
    // shouldActivateAccount: {
    //     type: Boolean,
    //     default: false,
    // },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    shouldShowDateOfRegistration: {
        type: Boolean,
        default: false,
    },
    validationErrorItems: {
        type: Object,
        default: false,
    },
    validatingAccountData: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["next-clicked"]);

// const supportingFormData = computed(() => {
//     return store.getters.getSupportingFormData;

// });

// const idOrBusinessText = computed(() => {
//     if (props.isIndividual === true) {
//         return "ID";
//     }
//     return "Business";
// });

// const primaryPhoneNumberInput = ref(null);

// const secondaryPhoneNumberInput = ref(null);

const form = ref(null);

// const userAgreesWithDisclaimer = ref(false);

// const websiteDisclaimerErrorMessages = ref([]);

// function validateDisclaimer() {
//     if (!userAgreesWithDisclaimer.value) {
//         websiteDisclaimerErrorMessages.value = [
//             "Please read and agree with the terms and conditions",
//         ];
//     } else {
//         websiteDisclaimerErrorMessages.value = [];
//     }
// }

// watch(userAgreesWithDisclaimer, () => {
//     validateDisclaimer();
// });

function validateFieldsAndMoveToNextStep() {

    const formValid = form.value.validate();

    if (formValid) {
        props.additionalDetailsModels.subcountyId = locationData.subcountyId
        props.additionalDetailsModels.wardId = locationData.wardId
        props.additionalDetailsModels.townId = locationData.townId

        emit("next-clicked");

    }
    else {
        scrollToError({
            containerElement: form.value.$el,
            couldBePhoneNumberError: true,
        });

    }

    //const primaryPhoneNumberIsValid = primaryPhoneNumberInput.value.validate();
    // const secondaryPhoneNumberIsValid = secondaryPhoneNumberInput.value.validate();

    //validateDisclaimer();

    // if (
    //     formValid &&
    //     primaryPhoneNumberIsValid &&
    //     secondaryPhoneNumberIsValid &&
    //     userAgreesWithDisclaimer.value
    // ) {
    // emit("next-clicked");
    // } else {
    // scrollToError({
    //     containerElement: form.value.$el,
    //     couldBePhoneNumberError: true,
    // });
    // }
}

// const passwordConfirmationRule = passwordConfirmation({
//     passwordGetter: () => props.accountDetailsModels.password,
// });

// const eighteenYearsAgo = getJsDateAsYMD(
//     new Date(Date.now() - convertYearsToMs(18))
// );

// const today = getJsDateAsYMD(new Date());

// const maxDate = ref(eighteenYearsAgo);

const dateFieldRules = ref([fieldRequired]);

// watchEffect(() => {
//     if (props.isIndividual) {
//         // maxDate.value = eighteenYearsAgo;
//         // props.accountDetailsModels.dateOfBirth = eighteenYearsAgo;
//     } else {
//         // maxDate.value = today;
//         // props.accountDetailsModels.dateOfBirth = today;
//     }
// });

const emailRules = computed(() => {
    const fieldIsRequired = !countyIsKirinyaga.value;
    return [
        fieldIsRequired ? fieldRequired : () => true,
        validateIfNotEmpty([emailValid]),
    ];
});

const newWards = computed(()=>{
    if (props.additionalDetailsModels.engineer_subcounty != '') {
        console.log("engineer_subcounty console")
        console.log(props.additionalDetailsModels.engineer_subcounty)

        console.log("wards wards")
        console.log(wards)

        
    }

    return [{'id':'1', 'name': 'my_name'}]
    
})

// const firstNameLabel = computed(() => {
//     return countyIsKirinyaga ? "Surname" : "First Name";
// });

// const lastNameLabel = computed(() => {
//     return countyIsKirinyaga ? "First Name" : "Last Name";
// });

// const dateOfBirthLabel = computed(() => {
//     if (props.isIndividual) {
//         return "Date of Birth";
//     }
//     return "Date of Registration";
// });

onMounted(() => {
    console.log("onMounted AdditionalDetails")
    console.log(subcounties)

});

const { subcounties, wards, towns, locationData, locationDataLoading } = useSubcountyWard();

</script>

<template>
    <Container>
        <template #content>
            <v-form @keyup.native.enter="validateFieldsAndMoveToNextStep" ref="form">
                <v-container fluid class="px-0">

                    <v-row>
                        <v-col cols="12" md="5">
                            <LabelledField label="Register As" required>
                                <v-select :items="['Individual', 'Firm']" item-text="name" item-value="id" outlined
                                    dense class="text-field-background" required v-model="additionalDetailsModels.register_as" 
                                    :rules="[fieldRequired]"
                                    hide-details="auto"></v-select>
                            </LabelledField>
                        </v-col>

                    </v-row>

                    <template v-if="accountDetailsModels.register_as == 'Physical / Town Planner'">
                        <!-- Physical/Town Planner  -->
                        <v-row v-if="additionalDetailsModels.register_as == 'Firm'">
                            <v-col cols="12" md="5">
                                <LabelledField label="Business/Company Name" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.phy_planner_company_name" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Company/Business Reg Number" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.phy_planner_business_reg_number" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>


                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Registration Number" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.phy_planner_reg_number" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Registration Email" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.phy_planner_email"
                                        :rules="[fieldRequired, emailRules]" hide-details="auto" :error-messages="validationErrorItems.email_address.errorValue ?? []
                                            "></v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="KRA Pin" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.phy_planner_kra_pin"
                                        :rules="[fieldRequired]" hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>
                        </v-row>

                    </template>

                    <template v-else-if="accountDetailsModels.register_as == 'Architect'">
                        <!-- Architect Additional Details  -->

                        <v-row v-if="additionalDetailsModels.register_as == 'Firm'">
                            <v-col cols="12" md="5">
                                <LabelledField label="Business/Company Name" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.company_name"
                                        :rules="[fieldRequired]" hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>
                            <v-col cols="12" md="5">
                                <LabelledField label="Business/Company Reg Number" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.company_reg_number"
                                        :rules="[fieldRequired]" hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Borasqs Number" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.boraqsNumber" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Borasqs Registration Email" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.boraqsRegEmail"
                                        :rules="[fieldRequired, emailRules]" hide-details="auto" :error-messages="validationErrorItems.email_address.errorValue ?? []
                                            "></v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="12" md="5">
                                <LabelledField label="KRA Pin" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.kra_pin" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="License Expiry Date" required>
                                    <DatePicker :rules="dateFieldRules" :textFieldProps="{
                                        outlined: true,
                                        dense: true,
                                        class: 'text-field-background',
                                        'hide-details': 'auto',
                                    }" only-show-value-on-change v-model="additionalDetailsModels.license_expiry_Date" />
                                </LabelledField>
                            </v-col>

                        </v-row>

                    </template>


                    <template v-else-if="accountDetailsModels.register_as == 'Structural Engineer'">
                        <!-- Structural Engineer Additional Details -->

                        <v-row v-if="additionalDetailsModels.register_as == 'Firm'">
                            <v-col cols="12" md="5">
                                <LabelledField label="Business/Company Name" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_business_name"
                                        :rules="[fieldRequired]" hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>
                            
                            <v-col cols="12" md="5">
                                <LabelledField label="Business/Company Reg Number" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_business_reg_number"
                                        :rules="[fieldRequired]" hide-details="auto" :error-messages="validationErrorItems.email_address.errorValue ?? []
                                            "></v-text-field>
                                </LabelledField>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Membership Number" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_member_num" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Membership Registration Email" required hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_reg_email"
                                        :rules="[fieldRequired, emailRules]" hide-details="auto" :error-messages="validationErrorItems.email_address.errorValue ?? []
                                            "></v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="KRA Pin" required>
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_kra_pin" :rules="[fieldRequired]"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>
                            

                            <v-col cols="12" md="5">
                                <LabelledField label="License Expiry Date" required>
                                    <DatePicker :rules="dateFieldRules" :textFieldProps="{
                                        outlined: true,
                                        dense: true,
                                        class: 'text-field-background',
                                        'hide-details': 'auto',
                                    }" only-show-value-on-change v-model="additionalDetailsModels.engineer_license_expiry_date" />
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Postal Address">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_postal_address"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Postal Town" hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_postal_town"
                                        hide-details="auto">
                                    </v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Postal Code">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_postal_code"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>

                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Physical Address" hint="">
                                    <v-text-field class="text-field-background" outlined dense required
                                        v-model="additionalDetailsModels.engineer_physical_address"
                                        hide-details="auto"></v-text-field>
                                </LabelledField>
                            </v-col>

                        </v-row>


                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Subcounty">
                                    <v-select :items="subcounties" item-text="sub_county_name"
                                        item-value="id" outlined dense class="text-field-background" required
                                        v-model="locationData.subcountyId" hide-details="auto"></v-select>
                                </LabelledField>
                            </v-col>

                            <v-col cols="12" md="5">
                                <LabelledField label="Ward">
                                    <v-select :items="wards" item-text="ward_name"
                                        item-value="id" outlined dense class="text-field-background" required
                                        v-model="locationData.wardId" hide-details="auto"></v-select>
                                </LabelledField>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="12" md="5">
                                <LabelledField label="Town">
                                    <v-select :items="towns" item-text="town_name"
                                        item-value="id" outlined dense class="text-field-background" required
                                        v-model="locationData.townId" hide-details="auto"></v-select>
                                </LabelledField>
                            </v-col>
                        </v-row>

                    </template>

                    <v-row>
                        <!-- Mode of Notification -->
                        <v-col cols="12" md="7">
                            <LabelledField label="Preffered Mode of Notification">
                                <v-select :items="['', 'Email', 'SMS', 'Both Email and SMS']" item-text="name"
                                    item-value="id" outlined dense class="text-field-background" required
                                    v-model="additionalDetailsModels.preferredNotificationMode" hide-details="auto"></v-select>
                            </LabelledField>
                        </v-col>
                    </v-row>

                </v-container>
            </v-form>
        </template>

        <template #nextButton>
            <!-- <v-btn depressed rounded color="secondary" :block="true" class="text-none" @click="validateFieldsAndMoveToNextStep"
        :loading="validatingAccountData" v-bind="$attrs">Next</v-btn> -->
            <NextButton :loading="validatingAccountData" @click="validateFieldsAndMoveToNextStep">
                <template #loadingText> Please Wait </template>
            </NextButton>
        </template>

        <template #previousButton>
            <PreviousButton @click="$emit('previous-clicked')" />
        </template>
    </Container>
</template>

<style lang="scss" scoped>
:deep(.password-container) {

    & input,
    & .v-input__slot {
        cursor: help;
    }
}
</style>
