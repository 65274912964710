import { useOtpStatus } from "../otp/business_logic";
import { reactive, computed, ref, onMounted } from "vue";
import apiCall from "@/utils/api";
import {
  requestStatus,
  otpStatus,
  INVALID_OTP,
  checkAccountStatus,
} from "@/utils/constants";
import {
  stringNotEmpty,
  isOtpInvalid,
  getHashedPassword,
  isValidationError,
  formatDatabasePhoneNumber,
} from "@/utils/functions";
import { getPhoneNumberWithCallingCode } from "@/utils/phone_number";
import store from "@/store/index";
import { GET_CUSTOMER_TYPES } from "@/store/getters/user";
import {
  countyIsKirinyaga,
  getCounty,
  supportingFormData,
  supportingFormDataLoading,
  useValidationErrors,
} from "@/utils/vue_helpers";
import { useMutation } from "@tanstack/vue-query";
import { setSnackbar } from "@/components/utils/snackbar/logic";

export function useCreateAccount() {
  const steps = {
    ENTER_ID_OR_BIZ_NUMBER: 1,
    VERIFY_IDENTITY: 2,
    ENTER_ACCOUNT_DETAILS: 3,
    VERIFY_OTP: 4,
    ADDITIONAL_DETAILS: 5,
    PROCEED_TO_LOGIN: 6,
  };

  const currentStep = ref(steps.ENTER_ID_OR_BIZ_NUMBER);
  const nonActivatedAccountExists = ref(false);
  const showAccountDetailsStep = ref(false);
  const userRedirectedFromLogin = ref(false);
  const userAccountAlreadyActivated = ref(false);
  const shouldActivateAccount = computed(() => {
    return nonActivatedAccountExists.value === true;
  });
  const shouldCreateAccount = computed(() => {
    return nonActivatedAccountExists.value === false;
  });

  function goToEnterIdNumberStep() {
    currentStep.value = steps.ENTER_ID_OR_BIZ_NUMBER;
  }

  function prepareVerifyIdentityStep(accountDetailsFromId) {
    clearPrefilledAccountDetails();
    setCustomerTypeToDefault();
    obscuredAccountDetails.value = accountDetailsFromId;
    nonActivatedAccountExists.value = accountDetailsFromId.status === 200;
    if (nonActivatedAccountExists.value === true) {
      const userHasAlreadyActivatedAccount =
        accountDetailsFromId.accountActivated === true;
      if (userHasAlreadyActivatedAccount) {
        checkAccountRequestStatus.value = checkAccountStatus.accountActivated;
        return;
      }
      accountDetailsModels.customerTypeId = accountDetailsFromId.customerTypeId;
      accountDetailsModels.customerName = accountDetailsFromId.customerName;
      if (countyIsKirinyaga.value) {
        setPrefilledAccountDetails(accountDetailsFromId);
      }
    }
    showAccountDetailsStep.value = true;
    goToVerifyIdentityStep();
  }

  function setPrefilledAccountDetails(serverResponse) {
    accountDetailsModels.phoneNumber1 = formatDatabasePhoneNumber(
      serverResponse.phoneNumber1
    );
    accountDetailsModels.phoneNumber2 = formatDatabasePhoneNumber(
      serverResponse.phoneNumber2
    );
    accountDetailsModels.emailAddress = serverResponse.emailAddress;
  }

  function clearPrefilledAccountDetails() {
    accountDetailsModels.phoneNumber1 = "";
    accountDetailsModels.phoneNumber2 = "";
    accountDetailsModels.emailAddress = "";
  }

  function setCustomerTypeToDefault() {
    accountDetailsModels.customerTypeId = 1;
    accountDetailsModels.customerName = null;
  }

  function goToVerifyIdentityStep() {
    currentStep.value = steps.VERIFY_IDENTITY;

  }

  function goToAccountDetailsStepVerifyData(){
    currentStep.value = 3

  }

  function goToAccountDetailsStepEdited(){
    currentStep.value = 3

  }

  function goToAccountDetailsStep() {
    // resetOtpToSendStage()

    if(accountDetailsModels.register_as == ''){
      currentStep.value = steps.ENTER_ACCOUNT_DETAILS;

    }
    else{
      currentStep.value = 4

    }


  }

  function goToAccountDetailsStepOptionSelected(){
    currentStep.value = 4

  }

  function goToAdditionalDetailsStep(){
    currentStep.value = steps.ADDITIONAL_DETAILS;
    
  }

  function goToVerifyOtpStep() {

    if(accountDetailsModels.register_as == ''){
      currentStep.value = steps.VERIFY_OTP;

    }
    else{
      currentStep.value = 5;

    }

  }

  const accountDetailsModels = reactive({
    firstName: "",
    lastName: "",
    otherName: "",
    organisationName: "",
    identificationNumber: "",
    customerTypeId: 1,
    phoneNumber1: "",
    phoneNumber1CountryCode: "KE",
    phoneNumber2: "",
    phoneNumber2CountryCode: "KE",
    emailAddress: "",
    confirmEmailAddress: "",
    register_as: "",
    dateOfBirth: "",
    postalTown: null,
    postalAddress: "",
    disabled: false,
    ncplwdNumber: "",
    ncplwdNumberIssuanceDate: "",
    genderId: null,
    password: "",
    passwordConfirmation: "",
    customerName: null,
    wards:[],
    towns:[],
  });

  const additionalDetailsModels = reactive({
    register_as: "",
    boraqsNumber: "",
    boraqsRegEmail: "",
    kra_pin: "",
    preferredNotificationMode: "",
    license_expiry_Date: "",
    company_name: "",
    company_reg_number: "",
    phy_planner_reg_number: "",
    phy_planner_business_reg_number: "",
    phy_planner_email: "",
    phy_planner_pass_num: "",
    phy_planner_company_name: "",
    phy_planner_kra_pin: "",
    engineer_member_num: "",
    engineer_reg_email: "",
    engineer_postal_address: "",
    engineer_postal_town: "",
    engineer_postal_code: "",
    engineer_physical_address: "",
    engineer_subcounty: "",
    engineer_ward: "",
    engineer_town: "",
    engineer_kra_pin: "",
    engineer_license_expiry_date: "",
    engineer_business_name: "",
    engineer_business_reg_number: "",
    subcountyId: "",
    wardId: "",
    townId: ""
    
  })

  // const accountDetailsModels = reactive({
  //     firstName: "fname",
  //     lastName: "lname",
  //     otherName: "",
  //     organisationName: 'Org',
  //     identificationNumber: "229872902",
  //     customerTypeId: 1,
  //     phoneNumber1: '765435648',
  //     phoneNumber1CountryCode: "KE",
  //     phoneNumber2: "",
  //     phoneNumber2CountryCode: "KE",
  //     emailAddress: "test457@mail.com",
  //     dateOfBirth: "1990-02-11",
  //     postalTown: 'AKALA',
  //     postalAddress: "43423",
  //     disabled: false,
  //     genderId: 1,
  //     password: '12345678A#',
  //     passwordConfirmation: '12345678A#',
  //     customerName: 'Customer Name'
  // });

  const {
    addErrorItem,
    setWatchersForClearOnChange,
    responseErrorRef,
    errorItems,
  } = useValidationErrors();
  addErrorItem({
    errorKey: "email_address",
    clearOnChangeFn: () => accountDetailsModels.emailAddress,
  });
  addErrorItem({
    errorKey: "phone_number1",
    clearOnChangeFn: () => accountDetailsModels.phoneNumber1,
  });
  addErrorItem({
    errorKey: "phone_number2",
    clearOnChangeFn: () => accountDetailsModels.phoneNumber2,
  });
  setWatchersForClearOnChange();

  const isDisabled = computed(() => {
    return accountDetailsModels.disabled === true;
  });

  const postalCode = computed(() => {
    const selectedPostalTown = accountDetailsModels.postalTown;
    const selectedPostalTownNotEmpty = stringNotEmpty(selectedPostalTown);
    const postalTownsList = supportingFormData.value.postalTowns;
    const postalTownsListNotEmpty =
      postalTownsList !== null && postalTownsList !== undefined;
    if (selectedPostalTownNotEmpty && postalTownsListNotEmpty) {
      const postalTown = postalTownsList.find(
        (postalTown) => postalTown.postal_town === selectedPostalTown
      );
      return postalTown.postal_code;
    }
    return "";
  });

  const obscuredAccountDetails = ref(null);
  const accountDetailsKeys = {
    "Phone Number": "obscuredPhoneNumber",
    Email: "obscuredEmailAddress",
    Name: "obscuredCustomerName",
  };
  const accountDetailsForVerificationStage = computed(() => {
    const nonEmptyAccountDetails = {};
    const obscuredAccountDetailsNotEmpty =
      obscuredAccountDetails.value !== null;
    if (obscuredAccountDetailsNotEmpty) {
      for (const [UiTitle, accountDetailsKey] of Object.entries(
        accountDetailsKeys
      )) {
        const possiblyEmptyAccountDetail =
          obscuredAccountDetails.value[accountDetailsKey];
        const accountDetailNotEmpty =
          possiblyEmptyAccountDetail !== null &&
          possiblyEmptyAccountDetail !== undefined &&
          possiblyEmptyAccountDetail !== "";
        if (accountDetailNotEmpty) {
          nonEmptyAccountDetails[UiTitle] = possiblyEmptyAccountDetail;
        }
      }
    }
    return nonEmptyAccountDetails;
  });

  const prefilledAccountDetails = reactive({
    phoneNumber1: "",
    phoneNumber2: "",
    emailAddress: "",
  });

  const individualCustomerTypeId = computed(() => {
    const individualCustomerType = store.getters[GET_CUSTOMER_TYPES].find(
      (customerType) => customerType.name === "Individual"
    );
    const individualCustomerTypeExists = individualCustomerType !== undefined;
    if (individualCustomerTypeExists) {
      return individualCustomerType.id;
    }
    return null;
  });

  const isIndividual = computed(() => {
    const individualCustomerTypeIdExists =
      individualCustomerTypeId.value !== null;
    if (individualCustomerTypeIdExists) {
      return (
        accountDetailsModels.customerTypeId === individualCustomerTypeId.value
      );
    }
    return null;
  });

  const idOrBusinessText = computed(() => {
    if (isIndividual.value === true) {
      return "ID";
    }
    return "Business";
  });

  const shouldShowDateOfRegistration = computed(() => {
    if (supportingFormDataLoading.value) {
      return false;
    }
    const corporateCustomerType = supportingFormData.value.customerTypes.find(
      (customerType) => customerType.name === "Corporate"
    );
    const customerTypeIsCorporate =
      accountDetailsModels.customerTypeId === corporateCustomerType.id;
    return customerTypeIsCorporate;
  });

  const checkAccountRequestStatus = ref(requestStatus.NOT_SENT);
  async function checkAccount() {
    const requestData = {
      id_number: accountDetailsModels.identificationNumber,
      county: getCounty(),
    };
    try {
      checkAccountRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url: "/api/client/check-account",
        data: requestData,
        method: "POST",
      });
      checkAccountRequestStatus.value = requestStatus.COMPLETE;
      prepareVerifyIdentityStep(response);
    } catch (error) {
      console.log(error);
      checkAccountRequestStatus.value = requestStatus.ERROR;
      throw error;
    }
  }

  function getFullNameForIndividual() {
    const hasOtherName = stringNotEmpty(accountDetailsModels.otherName);
    const otherNameWithSpaceAppended = hasOtherName
      ? ` ${accountDetailsModels.otherName}`
      : "";
    if (countyIsKirinyaga.value) {
      const surname = accountDetailsModels.firstName;
      const firstName = accountDetailsModels.lastName;
      return `${firstName} ${surname}${otherNameWithSpaceAppended}`;
    }
    return `${accountDetailsModels.firstName} ${accountDetailsModels.lastName}${otherNameWithSpaceAppended}`;
  }

  function getCustomerName() {
    if (isIndividual.value === true) {
      return getFullNameForIndividual();
    }
    return accountDetailsModels.organisationName;
  }

  function getFormattedPrimaryAndSecondaryPhoneNumber() {
    const formattedPrimaryPhoneNumber = getPhoneNumberWithCallingCode(
      accountDetailsModels.phoneNumber1,
      accountDetailsModels.phoneNumber1CountryCode
    );
    const hasSecondaryPhoneNumber = stringNotEmpty(
      accountDetailsModels.phoneNumber2
    );
    let formattedSecondaryPhoneNumber = null;
    if (hasSecondaryPhoneNumber) {
      formattedSecondaryPhoneNumber = getPhoneNumberWithCallingCode(
        accountDetailsModels.phoneNumber2,
        accountDetailsModels.phoneNumber2CountryCode
      );
    }
    return { formattedPrimaryPhoneNumber, formattedSecondaryPhoneNumber };
  }

  function getDateOfBirthAndRegistration() {
    let dateOfRegistration = null;
    let dateOfBirth = null;
    if (isIndividual.value) {
      dateOfBirth = accountDetailsModels.dateOfBirth;
    } else {
      dateOfRegistration = accountDetailsModels.dateOfBirth;
    }
    return { dateOfRegistration, dateOfBirth };
  }

  function getCreateOrActivateAccountRequestDataAndUrl(otp) {
    const hashedPassword = getHashedPassword(accountDetailsModels.password);
    const { formattedPrimaryPhoneNumber, formattedSecondaryPhoneNumber } =
      getFormattedPrimaryAndSecondaryPhoneNumber();
    const { dateOfBirth, dateOfRegistration } = getDateOfBirthAndRegistration();

    let url = "";
    let requestData = {};
    if (shouldActivateAccount.value) {
      requestData = {
        id_number: accountDetailsModels.identificationNumber,
        phone_number1: formattedPrimaryPhoneNumber,
        phone_number2: formattedSecondaryPhoneNumber,
        email_address: accountDetailsModels.emailAddress,
        password: hashedPassword,
        date_of_birth: dateOfBirth,
        date_of_registration: dateOfRegistration,
        otp,
      };
      url = "/api/client/update-account";
    } else if (shouldCreateAccount.value) {
      let ncplwdNumber = null;
      let ncplwdNumberIssuanceDate = null;
      if (isDisabled.value === true) {
        ncplwdNumber = accountDetailsModels.ncplwdNumber;
        ncplwdNumberIssuanceDate =
          accountDetailsModels.ncplwdNumberIssuanceDate;
      }

      requestData = {
        id_number: accountDetailsModels.identificationNumber,
        firstName: accountDetailsModels.firstName,
        lastName: accountDetailsModels.lastName,
        customer_name: getCustomerName(),
        phone_number1: formattedPrimaryPhoneNumber,
        phone_number2: formattedSecondaryPhoneNumber,
        email_address: accountDetailsModels.emailAddress,
        password: hashedPassword,
        otp,
        gender_id: accountDetailsModels.genderId,
        postal_town: accountDetailsModels.postalTown,
        postal_code: postalCode.value,
        postal_address: accountDetailsModels.postalAddress,
        date_of_birth: dateOfBirth,
        date_of_registration: dateOfRegistration,
        disability: accountDetailsModels.disabled,
        customer_type_id: accountDetailsModels.customerTypeId,
        register_as: accountDetailsModels.register_as
      };
      url = "/api/client/create-account";
    }
    return { requestData, url };
  }
  const creatingOrActivatingAccountRequestStatus = ref(requestStatus.NOT_SENT);
  const { setOtpStatus, resetOtpStatusToInitial, otpInvalid } = useOtpStatus();
  
  async function createOrActivateAccount(otp) {

    const { requestData, url } =
      getCreateOrActivateAccountRequestDataAndUrl(otp);
    try {
      creatingOrActivatingAccountRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({
        url,
        data: {
          ...requestData,
          'additionalDetailsModels': additionalDetailsModels
        }
        ,
        method: "POST",
      });
      setOtpStatus(otpStatus.OTP_VALID);
      creatingOrActivatingAccountRequestStatus.value = requestStatus.COMPLETE;

      if(accountDetailsModels.register_as != ''){
        steps.PROCEED_TO_LOGIN = 6

      }
      else{
        steps.PROCEED_TO_LOGIN = 5

      }


      currentStep.value = steps.PROCEED_TO_LOGIN;
      
    } catch (error) {
      creatingOrActivatingAccountRequestStatus.value = requestStatus.ERROR;
      const responseError = error.response;
      const errorCode = responseError.status;
      const errorText = responseError.data.error;
      const otpIsInvalid = isOtpInvalid({ errorCode, errorText });
      if (otpIsInvalid) {
        creatingOrActivatingAccountRequestStatus.value = INVALID_OTP;
        setOtpStatus(otpStatus.OTP_INVALID);
      }
    }
  }

  function useValidateCreateUpdateAccountData() {
    async function validateCreateUpdateAccountData() {
      const { requestData } = getCreateOrActivateAccountRequestDataAndUrl(null);
      if (shouldActivateAccount.value) {
        requestData["is_update"] = true;
      }
      try {
        const response = await apiCall({
          url: "/api/client/account/validate-create-update-data",
          data: requestData,
          method: "POST",
        });
        return response;
      } catch (error) {
        throw error;
      }
    }

    const accountDataValidationMutation = useMutation({
      mutationFn: validateCreateUpdateAccountData,
    });

    async function validateAccountData() {

      currentStep.value = 4
      

      // accountDataValidationMutation.mutate(
      //   {},
      //   {
      //     onSuccess() {
      //       goToVerifyOtpStep();
      //     },
      //     onError(error) {
      //       if (isValidationError(error)) {
      //         responseErrorRef.value = error;
      //         setSnackbar({
      //           text: "Error. Please check your account details",
      //           color: "error",
      //           open: true,
      //         });
      //       }
      //     },
      //   }
      // );
    }

    return { validateAccountData, accountDataValidationMutation };

  }

  const { validateAccountData, accountDataValidationMutation } =
    useValidateCreateUpdateAccountData();

  return {
    accountDetailsModels,
    additionalDetailsModels,
    steps,
    currentStep,
    nonActivatedAccountExists,
    showAccountDetailsStep,
    createOrActivateAccount,
    creatingOrActivatingAccountRequestStatus,
    goToAccountDetailsStep,
    goToAccountDetailsStepVerifyData,
    goToAccountDetailsStepEdited,
    goToAccountDetailsStepOptionSelected,
    goToEnterIdNumberStep,
    goToVerifyIdentityStep,
    goToVerifyOtpStep,
    prepareVerifyIdentityStep,
    goToAdditionalDetailsStep,
    userRedirectedFromLogin,
    otpInvalid,
    resetOtpStatusToInitial,
    checkAccountRequestStatus,
    checkAccount,
    accountDetailsForVerificationStage,
    idOrBusinessText,
    isIndividual,
    shouldCreateAccount,
    shouldActivateAccount,
    isDisabled,
    shouldShowDateOfRegistration,
    errorItems,
    validateAccountData,
    accountDataValidationMutation,
    prefilledAccountDetails,
  };
}
