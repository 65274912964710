<script setup>
import EnterIdNumber from "./EnterIdNumber.vue";
import VerifyIdentity from "./VerifyIdentity.vue";
import EnterAccountDetails from "./EnterAccountDetails.vue";
import Otp from "../otp/index.vue";
import ProceedToLogin from "./ProceedToLogin.vue";
import ClickHereToLoginBtn from "@/components/utils/ClickHereToLoginBtn.vue";
import PreviousButton from "./PreviousButton.vue";
import { useCreateAccount } from "./business_logic";
import { requestStatus, INVALID_OTP, otpStatus } from "@/utils/constants";
import { countyIsKirinyaga, useRouter } from "@/utils/vue_helpers";
import { ref, watchEffect, computed, onMounted } from "vue";
import AdditionalDetails from "./AdditionalDetails.vue";

const {
  register_as,
  accountDetailsModels,
  additionalDetailsModels,
  steps,
  currentStep,
  nonActivatedAccountExists,
  showAccountDetailsStep,
  createOrActivateAccount,
  creatingOrActivatingAccountRequestStatus,
  goToAccountDetailsStep,
  goToAccountDetailsStepEdited,
  goToAccountDetailsStepOptionSelected,
  goToAccountDetailsStepVerifyData,
  goToEnterIdNumberStep,
  goToVerifyIdentityStep,
  goToVerifyOtpStep,
  goToAdditionalDetailsStep,
  prepareVerifyIdentityStep,
  userRedirectedFromLogin,
  otpInvalid,
  resetOtpStatusToInitial,
  checkAccountRequestStatus,
  checkAccount,
  accountDetailsForVerificationStage,
  idOrBusinessText,
  isIndividual,
  shouldCreateAccount,
  shouldActivateAccount,
  isDisabled,
  shouldShowDateOfRegistration,
  errorItems,
  validateAccountData,
  accountDataValidationMutation,
} = useCreateAccount();

const { route } = useRouter();


function pickNextSection(){

  if(accountDetailsModels.register_as == ''){
    goToAccountDetailsStep()

  }
  else{
    goToAccountDetailsStepVerifyData()

  }




}




watchEffect(() => {
  if (!!route.value) {
    const idNumber = route.value.query["id-number"];
    const idNumberNotEmpty = !!idNumber;
    if (idNumberNotEmpty) {
      accountDetailsModels.identificationNumber = idNumber;
    }
  }
});

const props = defineProps({
  activateAccountData: {
    type: Object,
    default: null,
  },
});

function handleUserRedirectedFromLogin() {
  const activateAccountDataNotNull = props.activateAccountData !== null;
  if (activateAccountDataNotNull) {
    userRedirectedFromLogin.value = true;
    const idNumber = props.activateAccountData.idNumber;
    accountDetailsModels.identificationNumber = idNumber;
    const activateAccountData = {
      ...props.activateAccountData,
      status: 200,
    };
    prepareVerifyIdentityStep(activateAccountData);
  }
}

onMounted(() => {
  handleUserRedirectedFromLogin();

});

const accountDetailsStepLabel = ref(null);

watchEffect(() => {
  if (showAccountDetailsStep.value === true) {
    const shouldCreateAccount = nonActivatedAccountExists.value === false;
    const shouldActivateAccount = nonActivatedAccountExists.value === true;
    if (shouldCreateAccount) {
      accountDetailsStepLabel.value = "Customer Details";
    } else if (shouldActivateAccount) {
      accountDetailsStepLabel.value = "Customer Details";
    }
  }
});

const creatingOrActivatingAccount = computed(() => {
  return (
    creatingOrActivatingAccountRequestStatus.value === requestStatus.SENDING
  );
});

async function onCheckAccount() {
  await checkAccount();
}

const hasEnteredEmailAddress = computed(
  () => !!accountDetailsModels.emailAddress
);

function goBackToAccountDetailsToAddEmailAddress(resetOtpFunc) {
  resetOtpFunc();
  goToAccountDetailsStep();
}
</script>

<template>
  <v-container fluid class="create-activate-container pl-md-6">
    <v-row no-gutters class="mb-4">
      <v-col cols="12">
        <div :class="{
          'text-h6 primary--text': true,
          'text-center': $mobileBreakpoint,
        }">
          Create / Activate Account
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-0">
        <v-stepper flat vertical class="" v-model="currentStep">
          <v-stepper-step step="1" :class="{
            '': true,
            'text-body-1': !$mobileBreakpoint,
            'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
          }">
            <span :class="{ 'black--text': !$mobileBreakpoint }">
              Enter ID/Business Number
            </span>
          </v-stepper-step>

          <v-stepper-content step="1" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
            <EnterIdNumber :account-details-models="accountDetailsModels"
              :check-account-request-status="checkAccountRequestStatus" @check-account="onCheckAccount" />
          </v-stepper-content>

          <v-stepper-step step="2" :class="{
            'text-body-1': !$mobileBreakpoint,
            'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
          }">
            <span :class="{ 'black--text': !$mobileBreakpoint }">
              Verify Identity
            </span>
          </v-stepper-step>

          <v-stepper-content step="2" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
            <VerifyIdentity :account-details-for-verification-stage="accountDetailsForVerificationStage
              " :account-details-models="accountDetailsModels"
              :non-activated-account-exists="nonActivatedAccountExists" :id-or-business-text="idOrBusinessText"
              :user-redirected-from-login="userRedirectedFromLogin" @previous-clicked="goToEnterIdNumberStep"
              @next-clicked="pickNextSection" />
          </v-stepper-content>

          <template v-if="showAccountDetailsStep">
            <v-stepper-step step="3" :class="{
              'text-body-1': !$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
            }">
              <span :class="{ 'black--text': !$mobileBreakpoint }">
                {{ accountDetailsStepLabel }}
              </span>
            </v-stepper-step>

            <v-stepper-content step="3" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
              <EnterAccountDetails :account-details-models="accountDetailsModels" :is-individual="isIndividual"
                :should-create-account="shouldCreateAccount" :should-activate-account="shouldActivateAccount"
                :is-disabled="isDisabled" :should-show-date-of-registration="shouldShowDateOfRegistration"
                :validation-error-items="errorItems" :validating-account-data="accountDataValidationMutation.isLoading.value
                  " @previous-clicked="goToVerifyIdentityStep" @next-clicked="validateAccountData()" />
            </v-stepper-content>

            <template v-if="accountDetailsModels.register_as != ''">
              <v-stepper-step step="4" :class="{
                'text-body-1': !$mobileBreakpoint,
                'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
              }">
                <span :class="{ 'black--text': !$mobileBreakpoint }">
                  Additional Details
                </span>

                <h5 class="text-subtitle-1" v-if="accountDetailsModels.register_as == 'Architect'">Architects Additional
                  Details Form</h5>
                <h5 class="text-subtitle-1" v-else-if="accountDetailsModels.register_as == 'Engineer'">Engineers
                  Additional Details Form</h5>
                <h5 class="text-subtitle-1" v-else-if="accountDetailsModels.register_as == 'Physical / Town Planner'">
                  Planners Additional Details Form</h5>

              </v-stepper-step>

              <v-stepper-content step="4" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
                <AdditionalDetails :account-details-models="accountDetailsModels"
                  :additional-details-models="additionalDetailsModels" :is-individual="isIndividual"
                  :should-create-account="shouldCreateAccount" :should-activate-account="shouldActivateAccount"
                  :is-disabled="isDisabled" :should-show-date-of-registration="shouldShowDateOfRegistration"
                  :validation-error-items="errorItems" :validating-account-data="accountDataValidationMutation.isLoading.value
                    " @previous-clicked="goToAccountDetailsStepEdited" @next-clicked="goToVerifyOtpStep()" />
              </v-stepper-content>

            </template>

            <v-stepper-step :step="accountDetailsModels.register_as != '' ? 5 : 4" :class="{
              'text-body-1': !$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
            }">
              <span :class="{ 'black--text': !$mobileBreakpoint }">
                Enter Verification Code
              </span>
            </v-stepper-step>

            <v-stepper-content :step="accountDetailsModels.register_as != '' ? 5 : 4" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
              <Otp v-if="currentStep === (accountDetailsModels.register_as != '' ? 5 : 4)"
                :phoneNumber="accountDetailsModels.phoneNumber1"
                :countryCode="accountDetailsModels.phoneNumber1CountryCode"
                :emailAddress="accountDetailsModels.emailAddress" :otpInvalid="otpInvalid"
                :verifying="creatingOrActivatingAccount" :resetOtpStatusToInitialFn="resetOtpStatusToInitial"
                :sendOtpImmediately="countyIsKirinyaga" @verifyOtpClicked="createOrActivateAccount">
                <template v-if="!hasEnteredEmailAddress" #additionalResendOtp="{ resetOtpToSendStage }">
                  Or
                  <a class="" @click="
                    goBackToAccountDetailsToAddEmailAddress(
                      resetOtpToSendStage
                    )
                    ">click here</a>
                  to go back and add an email address and receive your
                  verification code via your email address
                </template>
              </Otp>

              <PreviousButton v-if="accountDetailsModels.register_as == ''"  class="mt-4 mt-md-10" :block="false" @click="goToAccountDetailsStep" />
              <PreviousButton v-else  class="mt-4 mt-md-10" :block="false" @click="goToAccountDetailsStepOptionSelected" />
            </v-stepper-content>

            <v-stepper-step :step="accountDetailsModels.register_as != '' ? 6 : 5" :class="{
              'text-body-1': !$mobileBreakpoint,
              'stepper-step-mobile text-subtitle-2': $mobileBreakpoint,
            }">
              <span :class="{ 'black--text': !$mobileBreakpoint }">
                Proceed to login
              </span>
            </v-stepper-step>

            <v-stepper-content :step="accountDetailsModels.register_as != '' ? 6 : 5" :class="{ 'stepper-content-mobile': $mobileBreakpoint }">
              <ProceedToLogin :should-create-account="shouldCreateAccount" />
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-col>
    </v-row>

    <v-row dense align="center" class="mt-8">
      <v-col cols="auto">
        <div>
          <span class="text-body-2">Already have an account?</span>
        </div>
      </v-col>
      <v-col cols="auto">
        <ClickHereToLoginBtn />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.create-activate-container {
  & .stepper-step-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  & .stepper-content-mobile {
    padding-left: 4px;
    padding-right: 0;
    margin-left: 12px;
    margin-right: 0;
  }
}
</style>
